<script>
import CreditsListItem from '@/components/products/CreditsListItem'
import Distribution from '@/components/dialogs/credits/distribution'

import manipulateProduct from '@/mixins/manipulateProduct'

export default {
  name: 'CreditsList',
  mixins: [manipulateProduct],
  components: { CreditsListItem, Distribution },
  data () {
    return {
      loading: false,
      showDistributionDialog: false,
      selectedDistribution: {}
    }
  },
  apollo: {
    credits: {
      query: require('@/graphql/queries/v2/distributionBoutiqueItems.gql'),
      update ({ distributionBoutiqueItems }) {
        const enrichedData = distributionBoutiqueItems?.items?.map(boutiqueItem => {
          const couv = this.getProduitCouverture(boutiqueItem.item)
          boutiqueItem.couverture = { couleurs: couv.couleursDominantes, thumbnail: couv.thumbnail }
          boutiqueItem.distribution = boutiqueItem.distribution.map(d => ({ ...d, restant: d.compteur - d.count }))
          return boutiqueItem
        })

        return enrichedData
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    openDistributionDialog (distrib) {
      this.selectedDistribution = distrib
      this.showDistributionDialog = true
    },
    refetchDistributionBoutiqueItems () {
      this.$apollo.queries.credits.refetch()
    }
  }
}
</script>

<template lang="pug">
.credits-list-container.mt-5
  Distribution(v-if="showDistributionDialog" @toggle="showDistributionDialog = !showDistributionDialog" @refetchcredits="refetchDistributionBoutiqueItems" :distributionContainer="selectedDistribution")
  .credits-list
    credits-list-item(v-for="(credit, index) in credits" :key="'Credit-' + index" :credit="credit" @openDistributionDialog="openDistributionDialog")
</template>

<style lang="sass" scoped>
.credits-list
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 16px
  @media all and (max-width: $medium)
    padding-left: 1.5rem
    padding-right: 1.5rem
    grid-template-columns: repeat(2, 1fr)
  @media all and (max-width: $small)
    grid-template-columns: repeat(1, 1fr)
</style>
