export default {
  methods: {
    getProduitCouverture (produit) {
      let img = null
      if (produit?.images?.length > 0) {
        img = produit.images.find((e) => {
          return e.tagsVisibles.find(t => t?.id.includes('details-graphiques_image-couverture'))
        })
      }
      return img
    },
    getCreditsInfosFromNuagePaquetAndItemId (nuagePaquet, itemId) {
      const eleves = { compteur: 0, nuageItemsCount: 0 }
      const enseignants = { compteur: 0, nuageItemsCount: 0 }

      nuagePaquet.forEach(paquet => {
        paquet.items.forEach(i => {
          if (i.boutiqueItem?.id === itemId) {
            i.roles.forEach(r => {
              if (r.role === 'salle_classe_eleve') {
                eleves.compteur += r.compteur
                eleves.nuageItemsCount += r.nuageItemsCount
              } else if (r.role === 'salle_classe_enseignant') {
                enseignants.compteur += r.compteur
                enseignants.nuageItemsCount += r.nuageItemsCount
              }
            })
          }
        })
      })

      const output = {}
      if (eleves.compteur || eleves.nuageItemsCount) {
        output.eleves = eleves
      }
      if (enseignants.compteur || enseignants.nuageItemsCount) {
        output.enseignants = enseignants
      }
      return output
    }
  }
}
