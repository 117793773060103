<script>
import caracteristicTagSimplified from '@/components/products/caracteristicTagSimplified'
import goTo from '@/mixins/goTo'

export default {
  name: 'CreditsListItem',
  mixins: [goTo],
  props: {
    credit: { type: Object, required: true }
  },
  components: { caracteristicTagSimplified },
  computed: {
    globalCaracteristics () {
      return [
        { name: 'format', icon: ['far', 'book'], text: this.credit.item.formats[0].titre }
      ]
    },
    creditsCaracteristics () {
      const caracsGrid = []

      this.credit.distribution.forEach(d => {
        const caracRow = []

        // Duree or Fin
        const duree = this.credit?.distribution[0]?.duree
        if (duree > 0) {
          caracRow.push({
            name: 'duree', icon: ['fad', 'hourglass-end'], text: this.$tc('produit.valable-x-jours', duree, { duree })
          })
        }

        const finAt = this.credit?.distribution[0]?.finAt
        if (!duree > 0 && finAt) {
          caracRow.push({
            name: 'fin', icon: ['fad', 'calendar-alt'], text: this.$t('commandes.eligible-until') + this.$moment(finAt).format('DD MMM YYYY')
          })
        }

        // Role
        const roleName = d.role === 'salle_classe_enseignant' ? this.$t('produit.for-enseignant') : (d.role === 'salle_classe_eleve' ? this.$t('produit.for-eleve') : this.$t('produit.for-unknown'))
        caracRow.push({ name: 'role', icon: ['fad', 'chalkboard-teacher'], text: roleName })

        // Credits Count
        caracRow.push({ name: 'creditsRestant', icon: ['fas', 'database'], text: this.$t('produit.x-credits-restants', { restant: d.restant }) })
        caracRow.push({ name: 'creditsTotal', icon: ['fas', 'database'], text: this.$t('produit.x-credits-total', { total: d.compteur }) })

        caracsGrid.push({ distribution: d, caracRow })
      })

      return caracsGrid
    }
  },
  methods: {
    openDistributionDialog ({ distribution }) {
      this.$emit('openDistributionDialog', { distribution, item: this.credit?.item })
    },
    goToBoutique () {
      const productslug = this.credit.item?.produits?.length ? this.credit.item.produits[0]?.slug : null
      const itemSlug = this.credit.item?.slug
      if (productslug && itemSlug) {
        this.goToBoutiqueItem(productslug, itemSlug)
      }
    }
  }
}
</script>

<template lang="pug">
.credits-list-item
  v-card
    .credits-background(:style="{ background: credit.couverture.couleurs[0]}")
    .credits-card-main
      div
        v-card-title.mb-0.pb-1 {{ credit.item.titre }}
        caracteristic-tag-simplified.ml-2(v-for="pi in globalCaracteristics" :key="pi.name" :infos="pi")
        v-btn.ml-5.mt-2(@click="goToBoutique" outlined rouded small) {{ $t('produit.voir-boutique') }}

      v-avatar.ma-3(size="80" tile)
        v-img(:src="credit.couverture.thumbnail")

    .credits-card-row(v-for="creditRow in creditsCaracteristics" :key="creditRow.caracRow.name" :class="{ nomore: !creditRow.distribution.restant }")
      .credits-card-caracs-container
        caracteristic-tag-simplified.ml-2(v-for="pi in creditRow.caracRow" :key="pi.name" :infos="pi")
      v-card-actions.mt-0
        v-btn(@click="openDistributionDialog(creditRow)" outlined rouded small :disabled="!creditRow.distribution.restant") {{ $t('produit.distrib') }}
</template>

<style lang="sass" scoped>
.credits-list-item
  width: 100%
  .v-card
    padding-bottom: 6px
    position: relative
    .v-card__title
      line-height: 1.15rem
      font-size: 1rem
      margin-bottom: 12px
    .v-card__subtitle
      font-size: 14px
      font-weight: bold
    .credits-background
      position: absolute
      width: 100%
      height: 100%
      opacity: 0.6
      border-radius: inherit
    .credits-card-main
      position: relative
      z-index: 5
      display: flex
      flex-wrap: nowrap
      justify-content: space-between
      margin-bottom: 12px
    .credits-card-row
      position: relative
      z-index: 5
      display: flex
      justify-content: space-between
      align-items: center
      padding-left: 12px
      padding-right: 12px
      margin-bottom: 8px
      padding-top: 8px
      &.nomore
        opacity: 0.6
      &:not(:first-child)::before
        content: ''
        position: absolute
        height: 1px
        width: 60%
        left: 20%
        top: 0
        background: #777
</style>
