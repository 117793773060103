<script>
export default {
  name: 'PageHeader',
  props: {
    title: { type: String, required: false },
    subtitle: { type: String, required: false, default: null },
    backButtonTo: { type: Object, required: false },
    backButtonLabel: { type: String, required: false },
    chips: { type: Array, required: false }
  }
}
</script>

<template lang="pug">
  .page-header(color='secondary', dark)
    .header__inner-content
      v-btn.mb2.px4(
        v-if="! _.isEmpty(backButtonTo) && ! _.isEmpty(backButtonLabel)"
        :small='$store.state.App.mobileTemplate'
        :to='backButtonTo'
        rounded
        color='primary'
      )
        font-awesome-icon.mr2.f4(:icon="['fas', 'arrow-left']")
        span(dark) {{ backButtonLabel }}
      div.flex-grow-1
      .header-title
        div.main-title(v-if='title && title !== ""') {{ title }}
        v-skeleton-loader(v-else, type='text')
      .header-description(v-if="subtitle") {{ subtitle }}
      .header__extra-content(v-if="chips && chips.length")
        v-chip.primary.lighten-5.secondary--text.mr2.mt1(
          v-for="(chip, index) in chips"
          :key="index"
          small
        )
          v-skeleton-loader(v-if="chip.loading", type='button')
          font-awesome-icon.f6.mr2(v-if="!chip.loading && chip.icon" :icon="chip.icon")
          span(v-if="!chip.loading") {{ chip.label }}

</template>

<style lang="sass" scoped>
.page-header
  min-height: 250px
  border-radius: $border-radius-root
  background-color: var(--v-secondary-base)
  display: flex
  flex-direction: column
  position: relative
  overflow: hidden
  .header-image
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    opacity: 0.45
  .header__inner-content
    position: relative
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: flex-end
    height: 100%
    padding: 50px $responsive-gutter-small
    > a
      width: max-content
      padding: 0 32px
    .header-title
      color: white
      margin-top: 20px
      line-height: 1.25
      max-width: 800px
      .secondary-title
        font-size: 20px
      .main-title
        font-size: 40px
        font-family: $display-font
        font-weight: $bold
    .header-description
      color: white
      margin: 10px 0
      font-size: 16px
      max-width: 800px
    .header__extra-content
      height: fit-content
      transform: translateY(10px)
  @media all and (max-width: $large)
    min-height: 200px
    .header__inner-content
      padding: 20px $responsive-gutter-small
      .header-title
        .secondary-title
          font-size: 16px
        .main-title
          font-size: 30px
  @media all and (max-width: $medium)
    border-radius: 0 0 0 0

</style>
