<script>
export default {
  name: 'CaracteristicTagSimplified',
  props: {
    infos: { type: Object, required: true }
  }
}
</script>

<template lang="pug">
div.caracteristic-tag-simplified.d-flex.align-center(v-if="!infos.skip")
  font-awesome-icon(v-if="infos.icon" :icon="infos.icon")
  p.ma-0(:class="{ 'font-weight-bold': infos.bold }") {{ infos.text }}
</template>

<style lang="sass" scoped>
.caracteristic-tag-simplified
  gap: 10px
  width: fit-content
  font-size: 12px
  transition: all 0.4s ease
  padding: 2px 12px
  border-radius: 12px
  margin: 2px 0
  &:hover
    background-color: rgba(50, 50, 50, 0.15)
</style>
