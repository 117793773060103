<script>
import goTo from '@/mixins/goTo'

export default {
  name: 'Distribution',
  mixins: [goTo],
  props: {
    distributionContainer: { type: Object, required: true }
  },
  data () {
    return {
      isOpen: true,
      selectedProf: {},
      nbCreditsSelected: 1,
      loadingDistribution: false
    }
  },
  computed: {
    distribution () {
      return this.distributionContainer.distribution
    },
    item () {
      return this.distributionContainer.item
    },
    role () {
      return this.distribution.role === 'salle_classe_eleve' ? this.$t('produit.role-eleves') : this.$t('produit.role-enseignants')
    },
    enseignants () {
      return this.$store.getters['Compte/enseignants']
    },
    buttonLabel () {
      const name = this.selectedProf?.name
      if (name) {
        return this.$t('produit.distrib-label-complete', {
          nbCreditsSelected: this.nbCreditsSelected,
          role: this.role,
          name
        })
      } else {
        return this.$t('produit.distrib-label', {
          nbCreditsSelected: this.nbCreditsSelected,
          role: this.role
        })
      }
    }
  },
  methods: {
    displayName (prof) {
      return `${prof.name} (@${prof.info?.username})`
    },
    async distributeCredits () {
      this.loadingDistribution = true

      const { debutAt, finAt, duree, role } = this.distribution
      const variables = {
        item: this.item.id,
        usager: this.selectedProf.id,
        total: Number(this.nbCreditsSelected),
        cle: { debutAt, finAt, duree, role }
      }

      const { data } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/distribuerBoutiqueItem.gql'),
        variables: variables
      })

      // Display Error/Success confirmation on the page
      if (data.distribuerBoutiqueItem) {
        this.$store.dispatch('App/setSnackBarNotification', {
          type: 'success',
          description: this.$t('alerte.distribute-credits-success')
        })
      } else {
        this.$store.dispatch('App/setSnackBarNotification', {
          type: 'error',
          description: this.$t('alerte.distribute-credits-error')
        })
      }

      this.loadingDistribution = false
      this.$emit('toggle')
      this.$emit('refetchcredits')
    },
    goToBoutique () {
      const productslug = this.item?.produits?.length ? this.item.produits[0]?.slug : null
      const itemSlug = this.item?.slug
      if (productslug && itemSlug) {
        this.goToBoutiqueItem(productslug, itemSlug)
      }
    }
  }
}
</script>

<template lang="pug">
v-dialog.distribution(v-model="isOpen" max-width="900" content-class="custom-dialog" persistent)
  v-card.light
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click="$emit('toggle')" depressed fab x-large)
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title.secondary.white--text {{ $t('produit.distrib-credits') }}

    //- We do not have credits left
    v-card-text.dark--text(v-if="!distribution.restant")
      h2 {{ item.titre }}

      h3.mt-2.mb-4 {{ $t('produit.no-more-credits', { role }) }}

      v-btn(color='primary' depressed block x-large @click="goToBoutique")
        span.f4 {{ $t('produit.buy-credits-again', { role }) }}

    //- We have credits that we can distribute
    v-card-text.dark--text(v-else)
      h2 {{ item.titre }}
      h3.mt-2.mb-4 {{ $t('produit.credits-restant-distrib', { restant: distribution.restant, role }) }}

      div.dialog-text.py-4
        v-select.my-2(
          v-model="selectedProf"
          :items="enseignants"
          :item-text="displayName"
          item-value="id"
          :label="$t('produit.select-prof')"
          return-object
        )

        v-text-field.my-2(
          v-model="nbCreditsSelected"
          hide-details
          type="number"
          :label="$t('produit.select-number-credits')"
          :min="1"
          :max="distribution.restant"
        )

      v-btn(color='primary' @click="distributeCredits" depressed block x-large :disabled='!selectedProf.name || !(nbCreditsSelected > 0)')
        span.f4 {{ buttonLabel }}
</template>
