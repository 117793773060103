export default {
  methods: {
    openLinkInNewTab (url) {
      window.open(url, '_blank')
    },
    goToBoutiqueItem (productSlug, itemSlug) {
      const url = `https://boutique.dokoma.com/fr/produits/${productSlug}/${itemSlug}`
      this.openLinkInNewTab(url)
    },
    goToBoutiqueCommande (commandeId) {
      const url = `https://boutique.dokoma.com/fr/commandes/${commandeId}`
      this.openLinkInNewTab(url)
    }
  }
}
