<script>
import PageHeader from '@/components/shared/pageHeader'
import CreditsList from '@/components/products/CreditsList'

export default {
  name: 'Bibliotheque',
  components: {
    PageHeader,
    CreditsList
  }
}
</script>

<template lang="pug">
.bibliotheque-widget
  page-header(:title="$t('menu.mes-cahiers')", :backButtonLabel="$t('menu.home')", :backButtonTo="{ name: 'home___' + $i18n.locale, params: { lang: $i18n.locale } }")
  credits-list
</template>
<style>
</style>
